import React from 'react';
import {Typography, Paper} from '@mui/material';
import TinyAudioPlayer from "./TinyAudioPlayer";

const MicroservicesDescription = () => {
    return (
        <Paper
            elevation={3}
            sx={{
                padding: '2rem',
                backgroundColor: '#1e1e1e',
                color: 'white',
                borderRadius: '8px',
                maxWidth: '1000px',
                margin: '2rem auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition
                '&:hover': {
                    backgroundColor: '#292929', // Darker background on hover
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)', // Enhanced shadow on hover
                },
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    textAlign: 'center',
                    transition: 'color 0.3s ease, transform 0.3s ease', // Smooth transition for color and transform
                    '&:hover': {
                        color: '#61dafb', // Change text color on hover
                        transform: 'scale(1.05)', // Slightly scale up the title on hover
                    },
                }}
            >
                Some of microservices I've worked on
            </Typography>
            <Typography variant="body1" sx={{lineHeight: 1.6}}>
                Here are a few of the microservices that I have developed as part of my experience as a software
                developer, each handling specific business functionalities:
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-1.mp3"/>
                <br/>
                1. <strong>Core processes microservice</strong>:
                microservice where synchronization was critical, utilizing Redis cache and Redis pub-sub
                for efficient real-time data updates and notifications. The microservice incorporated various real-time
                communication mechanisms while ensuring an optimized and high-performance data layer powered by Spring
                Data and Hibernate.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-2.mp3"/>
                <br/>
                2. <strong>AI Integration Gateway Service</strong>: I developed a microservice that acted as a gateway
                to integrate
                third-party AI providers, processing data through Azure Service Bus for seamless communication with
                other parts of the application.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-3.mp3"/>
                <br/>
                3. <strong>Cache-Optimized Data Retrieval Service</strong>: microservice designed to read
                data directly from Redis, minimizing database interaction to improve performance and reduce latency. For
                complex scenarios requiring advanced operations, Lua scripts were utilized to execute logic directly
                within the Redis cache, further optimizing efficiency.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    marginTop: '1rem',
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb', // Change text color on hover
                        transform: 'scale(1.02)', // Slightly scale up text on hover
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-4.mp3"/>
                <br/>
                4. <strong>Data Migration and In-Flight Processing Microservice</strong>: I developed a microservice
                designed to efficiently copy data from one database
                to another, handling columns with over a million records, while also supporting in-flight data
                processing to ensure consistency during the transfer.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-5.mp3"/>
                <br/>
                5. <strong>Reporting Microservice</strong>: Generates analytical reports based on data from other
                services,
                providing insights for business. Uses batch processes, Spring Data and Azure Service Bus for processing
                datasets efficiently.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-6.mp3"/>
                <br/>
                6. <strong>Administrative Service Microservice</strong>: A microservice responsible for managing user
                roles and
                permissions, ensuring that only authorized personnel could perform specific actions. The service
                utilized JWT (JSON Web Tokens) and Spring Security to handle authentication and role-based access
                control (RBAC). Implemented custom authentication filters to enforce security policies, while ensuring
                smooth integration with external identity providers through OAuth2 and Single Sign-On (SSO). The
                microservice also featured audit logging for all actions, providing transparency and traceability for
                administrative activities.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                        color: '#61dafb',
                        transform: 'scale(1.02)',
                    },
                }}
            >
                <br/>
                <TinyAudioPlayer src="/music/microservice-7.mp3"/>
                <br/>
                7. <strong>Tenant Management Microservice</strong>: A microservice dedicated to handling
                multi-tenant architecture, where each tenant had isolated databases and specific configurations. The
                service dynamically managed database connections, provisioning tenant databases, and ensuring data
                separation and security. Leveraged Spring Boot with JPA to seamlessly switch between tenant databases
                based on the current request context. It also integrated with Azure Service Bus for handling
                asynchronous updates
                and events related to tenant activities and utilized Redis for caching tenant metadata for optimized
                access and performance. The microservice ensured scalability and efficient handling of tenant-specific
                operations, such as tenant onboarding, updates, and data migrations.
            </Typography>

            {/*<Typography*/}
            {/*    variant="body1"*/}
            {/*    sx={{*/}
            {/*        transition: 'color 0.3s ease, transform 0.3s ease',*/}
            {/*        '&:hover': {*/}
            {/*            color: '#61dafb',*/}
            {/*            transform: 'scale(1.02)',*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <br/>*/}
            {/*    <TinyAudioPlayer src="/music/microservice-8.mp3"/>*/}
            {/*    <br/>*/}
            {/*    8. <strong>Command Management Microservice with MQTT</strong>: This microservice was designed to manage*/}
            {/*    and process commands using the MQTT protocol for lightweight, efficient message brokering. It enabled*/}
            {/*    real-time command distribution and feedback across IoT devices and internal services, ensuring reliable*/}
            {/*    and secure communication. The service supported message filtering, persistence, and quality of service*/}
            {/*    (QoS) settings for optimized delivery. Leveraging Spring Boot for the backend, it efficiently handled*/}
            {/*    MQTT topics and subscriptions, providing a scalable solution for command orchestration and ensuring*/}
            {/*    seamless communication with various devices and services in a distributed environment.*/}
            {/*</Typography>*/}

        </Paper>
    );
};

export default MicroservicesDescription;

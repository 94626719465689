import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {solarizedlight} from 'react-syntax-highlighter/dist/esm/styles/prism';
import GlowingBorderWithStyles from './GlowingBorder';

const CodeSnippet = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('javascript'); // State to toggle between snippets

    // JavaScript snippet
    const jsCodeString = `const helloWorld = () => {
    console.log("Hello, World! I'm Stefan Markov.");
};`;

    // Java snippet
    const javaCodeString = `public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World! I'm Stefan Markov.");
    }
}`;

    // TypeScript snippet
    const tsCodeString = `class HelloWorld {
    static main(): void {
        console.log("Hello, World! I'm Stefan Markov.");
    }
}`;

    const getCodeString = () => {
        switch (selectedLanguage) {
            case 'javascript':
                return jsCodeString;
            case 'java':
                return javaCodeString;
            case 'typescript':
                return tsCodeString;
            default:
                return '';
        }
    };

    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', paddingTop: '2rem'}}>
            <GlowingBorderWithStyles/>
            <Box
                sx={{
                    backgroundColor: '#1e1e1e',
                    width: 'auto',
                    padding: '2rem',
                    transition: 'background-color 0.5s, transform 0.5s',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    '&:hover': {
                        backgroundColor: '#292929'
                    },
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'start', marginBottom: '1rem'}}>
                    <Button
                        variant={selectedLanguage === 'javascript' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedLanguage('javascript')}
                        sx={{marginRight: '0.5rem', color: '#61dafb'}}
                    >
                        JavaScript
                    </Button>
                    <Button
                        variant={selectedLanguage === 'java' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedLanguage('java')}
                        sx={{marginRight: '0.5rem', color: '#61dafb'}}
                    >
                        Java
                    </Button>
                    <Button
                        variant={selectedLanguage === 'typescript' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedLanguage('typescript')}
                        sx={{color: '#61dafb'}}
                    >
                        TypeScript
                    </Button>
                </Box>
                <SyntaxHighlighter language={selectedLanguage} style={solarizedlight}>
                    {getCodeString()}
                </SyntaxHighlighter>
            </Box>
            <GlowingBorderWithStyles/>
        </div>
    );
};

export default CodeSnippet;

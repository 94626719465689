import React, {useEffect, useRef} from 'react';
import {Box, Grid, Typography, Paper} from '@mui/material';
import {FaJava, FaDocker, FaDatabase, FaGit, FaGithub} from 'react-icons/fa';
import {SiJira, SiConfluence, SiJsonwebtokens, SiTextpattern, SiAuth0} from 'react-icons/si';
import {
    SiSpring,
    SiHibernate,
    SiMysql,
    SiJunit5,
    SiSpringsecurity,
    SiSpringboot,
    SiSwagger,
} from 'react-icons/si';
import {FaVuejs} from 'react-icons/fa'; // Vue.js icon
import {SiHtml5} from 'react-icons/si'; // HTML icon
import {SiCss3} from 'react-icons/si'; // CSS icon
import {GiTestTubes} from 'react-icons/gi';
import {FaFileCode, FaPiedPiper} from "react-icons/fa6"; // Example for testing libraries
import {motion, useAnimation} from 'framer-motion'; // Import motion from framer-motion

const MonolithTechStack = () => {
    const technologies = [
        {name: 'Java', icon: <FaJava size={30}/>},
        {name: 'Spring Boot', icon: <SiSpringboot size={30}/>},
        {name: 'Spring Security', icon: <SiSpringsecurity size={30}/>},
        {name: 'OAuth', icon: <SiAuth0 size={30}/>},
        {name: 'JWT', icon: <SiJsonwebtokens size={30}/>},
        {name: 'Spring Data', icon: <SiSpring size={30}/>},
        {name: 'JPA', icon: <FaDatabase size={30}/>}, // Generic database icon for JPA
        {name: 'Hibernate', icon: <SiHibernate size={30}/>},
        {name: 'MySQL', icon: <SiMysql size={30}/>},
        {name: 'Jackson', icon: <FaFileCode size={30}/>},

        {name: 'Vue', icon: <FaVuejs size={30}/>},
        {name: 'HTML', icon: <SiHtml5 size={30}/>},
        {name: 'CSS', icon: <SiCss3 size={30}/>},

        {name: 'Docker', icon: <FaDocker size={30}/>},
        {name: 'JUnit 5', icon: <SiJunit5 size={30}/>},
        {name: 'Mockito', icon: <GiTestTubes size={30}/>},  // Mocking framework
        {name: 'AssertJ / Hamcrest', icon: <GiTestTubes size={30}/>},  // Testing assertions
        {name: 'H2 DB', icon: <FaDatabase size={30}/>},
        {name: 'Swagger', icon: <SiSwagger size={30}/>}, // Added Swagger
        {name: 'Lombok', icon: <FaPiedPiper size={30}/>},
        {name: 'Git', icon: <FaGit size={30}/>}, // Git added
        {name: 'GitHub', icon: <FaGithub size={30}/>}, // GitHub added
        {name: 'CodeQL', icon: <SiTextpattern size={30}/>},
        {name: 'Jira', icon: <SiJira size={30}/>},
        {name: 'Confluence', icon: <SiConfluence size={30}/>}
    ];

    const controls = useAnimation(); // Create an animation control
    const ref = useRef(); // Create a ref for the component

    // Use useEffect to detect when the component is in the viewport
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, y: 0 });
                } else {
                    controls.start({ opacity: 0, y: 20 });
                }
            },
            { threshold: 0.1 } // Adjust this to control how much of the component needs to be visible
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(ref.current);
            }
        };
    }, [controls]);

    return (
        <Paper
            elevation={3}
            sx={{
                padding: '2rem',
                backgroundColor: '#1e1e1e',
                color: 'white',
                borderRadius: '8px',
                maxWidth: '1000px',
                margin: 'auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            }}
            ref={ref} // Attach the ref to the Paper component
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    borderBottom: '4px solid #61dafb',
                    marginBottom: '2rem',
                    textAlign: 'center',
                }}
            >
                Technologies and tools
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {technologies.map((tech, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }} // Initial animation state
                            animate={controls} // Use animation controls for scroll effects
                            transition={{ duration: 0.5, delay: index * 0.1 }} // Staggered animation
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#292929',
                                    padding: '1rem',
                                    borderRadius: '8px',
                                    transition: 'transform 0.3s ease, background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#61dafb',
                                        transform: 'scale(1.05)',
                                    },
                                }}
                            >
                                {tech.icon}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginLeft: '1rem',
                                        color: 'white',
                                        transition: 'color 0.3s ease',
                                        '&:hover': {
                                            color: '#1e1e1e', // Change text color on hover
                                        },
                                    }}
                                >
                                    {tech.name}
                                </Typography>
                            </Box>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default MonolithTechStack;

import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import NavBar from './components/NavBar';
import AboutMe from "./components/AboutMe";
import InfoComponent from "./components/InfoComponent";
import MyExperience from "./components/MyExperience";
import Footer from "./components/Footer";
import Hobbies from "./components/Hobbies";
import Contact from "./components/Contact";
import GitHubAccounts from "./components/GitHubAccounts";
import AboutMePicture from "./components/UserPicture";
import Education from "./components/EducationComponent";
import ScrollProgressBar from "./components/ScrollProgressBar";
import ExperienceTree from "./components/ExperienceTree";
import CodeSnippet from "./components/CodeSnippet";
import InstallPrompt from "./components/InstallPrompt";
import NotFound from "./components/NotFound";
import CertificateLinks from "./components/CertificateLinks";
import StickyInfo from "./components/StickyInfo";
import StickyNotes from "./components/StickyNotes";
import StickyNavigator from "./components/StickyNavigator";
import PresentationThemes from "./components/Presentations";
import YouTubeChannel from "./components/YouTubeChannel";
import ContactForm from "./components/ContactForm";
import {sendData} from "./service/SupabaseService";

const App = () => {
    useEffect(() => {
        const executeSendData = async (type) => {
            try {
                await sendData(type);
            } catch (error) {
                console.error(" ");
            }
        };

        // First attempt with type "i"
        executeSendData("i").then(() => {
        });

        // Send data every 30 seconds with type "d"
        const interval = setInterval(() => executeSendData("d"), 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Router>
            <NavBar/>
            <ScrollProgressBar/>

            <Routes>
                <Route path="/leo" element={
                    <>
                        <AboutMePicture/>
                        <CodeSnippet/>
                        <Contact/>
                        <AboutMe/>
                        <MyExperience/>
                        <ExperienceTree/>
                        <InfoComponent/>
                        <Education/>
                        <PresentationThemes/>
                        <Hobbies/>
                        <YouTubeChannel/>
                        <GitHubAccounts/>
                        <InstallPrompt/>
                        <CertificateLinks/>
                        <ContactForm/>
                        <StickyInfo/>
                        <StickyNotes/>
                        <StickyNavigator/>
                    </>
                }/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>

            <Footer/>
        </Router>
    );
};

export default App;
